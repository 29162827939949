import { Map } from 'mapbox-gl';

namespace Publishers {
  let _listeners = [];

  export function Subscribe(listener: Function): Function {
    _listeners.push(listener);
    return listener;
  }

  export function Unsubscribe(listener: Function): void {
    _listeners = _listeners.filter(l => l !== listener);
  }

  export function Dispatch(zoomLevel: number): void {
    _listeners.forEach(l => l(zoomLevel));
  }
}

namespace Controllers {
  export function Log(map: Map): void {
    const zoomLevel = map.getZoom();
    const bbox = map.getBounds();
    const center = map.getCenter();
    console.table({
      zoomLevel: zoomLevel,
      bbox_ne: bbox.getNorthEast(),
      bbox_sw: bbox.getSouthWest(),
      center: center,
    });
  }
}

export { Publishers, Controllers };
