import { MapMouseEvent } from 'mapbox-gl';

namespace Publishers {
  let _listeners = [];

  export function Subscribe(listener: Function): Function {
    _listeners.push(listener);
    return listener;
  }

  export function Unsubscribe(listener: Function): void {
    _listeners = _listeners.filter(l => l !== listener);
  }

  export function Dispatch(e: MapMouseEvent): void {
    _listeners.forEach(l => l(e));
  }
}

export { Publishers };
